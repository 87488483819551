import React, { useEffect, useState } from "react";
import { navigate, graphql } from "gatsby";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import "isomorphic-fetch";

import { HelmetBlock } from "../components/HelmetBlock";
import { Header } from "../components/HeaderBlock";

import loader from "../images/loader.svg";

import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/index.css";

const Success = ({ data, location }) => {
  const [redirectTo, setRedirectTo] = useState("");
  const [eventSended, setEventSended] = useState(false);
  const isDebugMode =
    typeof window !== `undefined` &&
    window.localStorage.getItem("debugMode") === "on";

  // Debug
  useEffect(() => {
    if (isDebugMode) {
      console.debug("Success step state:", location.state);
    }
  }, []);

  useEffect(() => {
    if (!eventSended) {
      typeof window !== "undefined" &&
        window.fbq &&
        window.fbq("track", "CompleteRegistration");

      if (location.state && location.state.requestStatus === "OK") {
        typeof window !== "undefined" &&
          window.fbq &&
          window.fbq("track", "Lead");
      }
      setEventSended(true);
    }
  }, [eventSended]);

  useEffect(() => {
    setRedirectTo(location.state ? location.state.redirectTo : "/");

    if (location.state && location.state.redirectTo === "/comparador") {
      setTimeout(() => {
        navigate(location.state.redirectTo, {
          state: {
            name: location.state.name,
            lead: location.state.lead,
          },
        });
      }, 5000);
    } else {
      setTimeout(() => {
        typeof window !== "undefined" &&
          window.gtag &&
          window.gtag("event", "Submit1success");
        window.location.href = redirectTo;
      }, 5000);
    }
  });

  return (
    <StyledPageWrapperBlock>
      <HelmetBlock data={data.wpPage} />
      <Header data={data.wpPage} />
      <StyledFormBlock>
        <Container>
          <div className="form-stage2">
            <div className="main-text">
              <img src={loader} alt="" />
              <p>
                En breve te vamos a redirigir a la mejor opción.
                <br />
                Podrás continuar el proceso en la siguiente página.
              </p>
            </div>
            <p className="info-text">
              Si en 15 segundos no se redirecciona automáticamente,{" "}
              <a href={redirectTo}>haz click aquí</a>
            </p>
          </div>
        </Container>
      </StyledFormBlock>
    </StyledPageWrapperBlock>
  );
};

export default Success;

const StyledPageWrapperBlock = styled.div`
  min-height: 100vh;
  background: #f2f6fc;

  header {
    background: transparent;
  }
`;

const StyledFormBlock = styled.header`
  .main-text {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: #378aff;
    margin-top: 180px;
    position: relative;

    @media (min-width: 768px) {
      font-size: 28px;
      margin-top: 210px;
    }

    img {
      position: absolute;
      top: -100px;
      left: 50%;
      transform: translateX(-50%);
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      100% {
        transform: translateX(-50%) rotate(360deg);
      }
    }
  }

  .info-text {
    margin: 100px 0 0;
    text-align: center;
    font-size: 14px;

    @media (min-width: 768px) {
      margin: 150px 0 0;
      font-size: 17px;
    }
  }
`;

export const pageQuery = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      databaseId
      parentDatabaseId
      seo {
        title
        metaDesc
      }
    }
  }
`;
